@import url("colors.css");
html {
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
	font-smoothing: antialiased;
	-webkit-font-smoothing: antialiased;
}

html,
body,
#root {
	margin: 0;
	width: 100%;
	height: 100%;
}

body {
	font-family: 'Barlow', sans-serif;
}

/* REMOVE OUTLINE IN CHROME */
input:focus,
textarea:focus,
select:focus {
	border: 0px solid;
	@apply bg-white;
}

/* CHROME OVERRIDES */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	border: none;
	-webkit-text-fill-color: inherit;
	-webkit-box-shadow: inherit;
	transition: background-color 5000s ease-in-out 0s;
}

* {
	-webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important; 
    -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important; 
    outline: none !important;
} 

*:focus {
	outline: none !important;
}

.portal-hide-scrollbars {
	-ms-overflow-style: none;
}

.portal-hide-scrollbars::-webkit-scrollbar {
	width: 0;
}

.portal-flex {
	flex: 1 1 auto;
}

/* Elements Pages */
.portal-pages__header {
	flex-direction: row;
	box-sizing: border-box;
	display: flex;
	max-height: 100%;
	align-content: center;
	justify-content: center;
	align-items: center;
	min-height: 500px;
	background: rgba(0, 0, 0, 0.050);
}

.portal-pages__header-demo {
	max-width: 400px;
	margin-top: 0;
	margin-bottom: 150px;
	padding: 0px 48px;
	display: flex;
	flex: 1 1 auto;
	position: relative;
	justify-content: center;
}

.portal-pages__content-inner {
	margin-top: -200px;
	padding: 16px;
	box-shadow: 0px -40px 100px -50px rgba(0, 0, 0, 0.3), -20px 0px 40px -50px rgba(0, 0, 0, 0.2), 20px 0px 40px -50px rgba(0, 0, 0, 0.2);
	opacity: 0;
	transform: translateY(20px);
	animation: portal-pages__content-inner .3s forwards 1 ease-in-out;
}

@keyframes portal-pages__content-inner {
	0% {
		opacity: 0;
		transform: translateY(20px);
	}

	100% {
		opacity: 1;
		transform: translateY(0px);
	}
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus {
	background-color: #FFFFFF !important;
	color: #555 !important;
	-webkit-box-shadow: 0 0 0 1000px white inset !important;
	-webkit-text-fill-color: #555555 !important;
}



/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
	display: none;
}
  
/* Hide scrollbar for IE and Edge */
.hide-scrollbar {
	-ms-overflow-style: none;
}



.fade-in {
	display: inline-block;
	font-family: 'Montserrat';
	font-size: 30px;
	color: black;
	text-transform: none;
	margin: 0 20px;
	opacity: 0.0;
	animation: fadeIn ease 10s;
	-webkit-animation: fadeIn ease 10s;
}
	
@keyframes fadeIn {
	0% {opacity:0;}
	100% {opacity:1;}
}
	
@-moz-keyframes fadeIn {
	0% {opacity:0;}
	100% {opacity:1;}
}
	
@-webkit-keyframes fadeIn {
	0% {opacity:0;}
	100% {opacity:1;}
}
	
@-o-keyframes fadeIn {
	0% {opacity:0;}
	100% {opacity:1;}
}
	
@-ms-keyframes fadeIn {
	0% {opacity:0;}
	100% {opacity:1;}
}



.fade-out {
	opacity: 0;
	animation: fadeOut ease 500ms;
	-webkit-animation: fadeOut ease 500ms;
	border: none !important;
}
	
@keyframes fadeOut {
	0% {opacity:1;}
	100% {opacity:0;}
}
	
@-moz-keyframes fadeOut {
	0% {opacity:1;}
	100% {opacity:0;}
}
	
@-webkit-keyframes fadeOut {
	0% {opacity:1;}
	100% {opacity:0;}
}
	
@-o-keyframes fadeOut {
	0% {opacity:1;}
	100% {opacity:0;}
}
	
@-ms-keyframes fadeOut {
	0% {opacity:1;}
	100% {opacity:0;}
}


/* USED TO QUICKLY IDENTIFY DOM ELEMENTS DURING DEV */
red {
	border: 'solid 2px red';
}
blue {
	border: 'solid 2px blue';
}
green {
	border: 'solid 2px blue';
}
