:root {
	--black: #222222;
	--white: #ffffff;
	--purple: #8B6BD9;
	--background: #F5F5F5;
	--purple99: #9160DC99;
	--dash-purple: #8B6BD9;
	--dashnow: #8B6BD9;
	--heartland: #BA0C13;
	--shift4: #007cba;
	--fis: #8DC63F;
	--msg: #3A90CE;
	--elavon: #0075C9;
	--dash-orange: #F2B62F;
	--dash-gray: #9C9C9C;
	--purple-text: #9160DC;
	--divider: #DDDDDD;
    --text: #373737;
	--dark-gray: #4c4c4c;
	--gray: #666666;
	--light-gray: #838383;
	--header-font-size: 1.5em;
	--header-height: 62px;
	--footer-height: 62px;

	--vehicle-white: #FFFFFF;
	--vehicle-black: #000000;
	--vehicle-silver: #E4E4E4;
	--vehicle-gray: #777777;
	--vehicle-red: #CE0016;
	--vehicle-teal: #0091A6;
	--vehicle-blue: #001783;
	--vehicle-green: #006831;
	--vehicle-brown: #987700;
	--vehicle-other: #9160DC;
}